/**
 * This file contains all Entities and DTOs as "schema" used by the client and shared with the backend/API.
 * It also contains used enumerations (enums), and standard "fresh"-objects based on the schemas,
 * as well as examples for testing.
 */
import { AvailableContentItems } from "@components/common/contentmanagement/ContentManagementHelper"
import { StringBasedStringArray } from "@definitions/common"
import { EntityType } from "@redux/reduxTypes"
import { FeedbackPostContent } from "@services/feedbackHelper"

import { ExtendsWithAllPropertiesRequired } from "../definitions/typescript-util"

/* ************************************************************************** */
/* Interfaces: used schema for all entities and DTOs                          */
/* using the prefix I to have a standard in the code                          */
/* ************************************************************************** */

// #region basic technical schemas

/* ********** Technical schemas ********************************************* */

/**
 * IRI stub following the pattern /endpointurl
 * NOTE: the stub may possibly include more slashes, e.g. /end/point/url
 * but also invalid/unwanted combinations like e.g. /end///point
 */
export type IRIstub = `/${string}`

/**
 * pattern of an url of a sub resource under a base endpoint, e.g. /projects/7/memberships
 */
export type SubResourceUrlPattern = `/${string}/[id]/${string}`

/**
 * real sub resource url after the [id] was replaced by a real id
 */
export type SubResourceUrl = `/${string}/${number}/${string}`

/**
 * IProjectMembership uses a special IRI format:
 * /project_memberships/project=2;user=384
 *
 * @deprecated
 */
export type IRIOfProjectMembership = `${IRIstub}/${string}=${number};${string}=${number}`

/**
 * to mark IRI elements explitely they are typed as IRI, which is a string
 * of special pattern
 *
 * @see: https://stackoverflow.com/questions/51445767/how-to-define-a-regex-matched-string-type-in-typescript
 */
export type IRI = `${IRIstub}/${number}` | IRIOfProjectMembership

/**
 * That is the return type of those endpoints that do not return any data output
 * (entities, entity lists, downloads, ...),
 * e.g.:
 * - verification of account, email, password
 * - M2M endpoints
 * - delete endpoints, e.g. attachment definition
 * - lock/undlock/contact/contact users endpoints, e.g. for idea and program entity
 * ... and many more
 *
 * This type is called OperationResultOutput in the backend.
 */
export interface IOperationResultOutput {
  /**
   * Result string, e.g. "Verification successful"
   */
  result: string
  /**
   * Contains any data that may be relevant to the caller.
   * Especially: for account verification it contains the temporarily stored onboarding data
   * when the client provided such data when registering a user.
   */
  metadata: any[]
}

/**
 * An INativeHydraCollection is used for the transport of a collection of objects from the backend-API.
 */
export interface INativeHydraCollection<T> {
  /**
   * the context of the EntityType in the backend,
   * e.g. "/contexts/Project"
   */
  "@context"?: string
  /**
   * endpoint url of the entity type of the fetched entities
   * e.g. "/projects"
   */
  "@id"?: IRIstub
  /**
   * usually contains: "hydra:Collection"
   */
  "@type"?: string
  /**
   * an array of the fetched entities, filtered by the current used filter
   */
  "hydra:member"?: T[]
  /**
   * is a complex object that describes, what search/filter options are provided by the endpoint
   *
   * @todo https://futureprojects.atlassian.net/browse/FCP-1446
   */
  "hydra:search"?: object
  /**
   * Number of items that are available by the current used filter: is higher than the number of members when more
   * than one page is available.
   */
  "hydra:totalItems"?: number
  /**
   * Pagination meta data
   *
   * @see https://api-platform.com/docs/core/pagination/
   */
  "hydra:view"?: {
    /** url of the current page */
    "@id"?: string
    /** usually: "hydra:PartialCollectionView" */
    "@type"?: string
    /** url of the first page when the collection is parted into more than one page */
    "hydra:first"?: string
    /** url of the last page when the collection is parted into more than one page */
    "hydra:last"?: string
    /** url of the next page from the point of view of the current api collection call */
    "hydra:next"?: string
    /** url of the previous page from the point of view of the current api collection call */
    "hydra:previous"?: string
  }
}

/**
 * An IHydraCollection is used for the transport of a collection of entities based on IModel from the backend-API.
 */
export type IHydraCollection<T extends IModel> = INativeHydraCollection<T>

/**
 * structure of an error returned by the backend API
 */
export interface IHydraError {
  "@context": string
  "@type": string
  "hydra:title": string
  "hydra:description": string
}



/**
 * An IModel is the basic model of all used Entities.
 */
export interface IModel {
  /**
   * optional, to be undefined when creating a new entity: the IRI does not exist yet, the backend will create it
   */
  "@id"?: IRI
  /** usedPrivileges are those privileges the users request was handled by the backend (user may have more than one privilege, but the request was performed as one of them, e.g. PlatformManager) */
  usedPrivileges?: UserPrivileges[]
  /** true, if all data of the Object was retrieved; false, if the object was loaded as part of a collection only */
  detailResult?: boolean

  /** property is not available in single_multi
   *
   * @todo multi refactor function, UserRole is not part of IModel anymore
   * @see https://futureprojects.atlassian.net/browse/FCP-1441
   * @deprecated
   */
  usedRoles?: UserRole[]
}

/**
 * An INumericIdentifierModel extends the IBasisIModel by a numeric identifier,
 * so all unique entities can by identified by that id.
 */
export interface INumericIdentifierModel extends IModel {
  id?: number
}

/**
 * An ISlugIdentifierModel extends the INumericIdentifierModel by a string identifier,
 * so all unique entities can by identified by that slug.
 */
export interface ISlugAndNumericIdentifierModel extends INumericIdentifierModel {
  slug?: string
}

// #endregion

/* ********** Schemas for user and login handling ********************************************* */

/**
 * An IAuthReply represents a token for an authentificated connection between client and backend and can be used
 * to order a new token.
 */
export interface IAuthReply {
  /** encoded JWT */
  token: string
  /** token string to request a new JWT */
  refresh_token: string
  /** unix timestamp when the refresh-token expires */
  refresh_token_expires: number
}



export interface IEmailChange {
  confirmationPassword: string
  email: string
  verificationUrl?: string
}

export interface INewPasswordRequest {
  verificationUrl?: string
}

export interface IPasswordChange {
  confirmationPassword: string
  password?: string
}

export interface IPasswordReset extends IVerification {
  password: string
}

export interface IPasswordResetRequest {
  username: string
  verificationUrl?: string
}

export interface IVerification {
  id: string
  token: string
}

// is used to send an email to certain users and the sender will get a confirmation email
// @see src/redux/saga/users.ts: sendMenssageToAllUsersSaga
export interface IUserEmail extends ITeamEmail {
  receiverOption: UserEmailReceiverOption
}

/* ********** Content schemas ********************************************* */

/** all characters in uppercase to be used as typisation of ISO3116 */
type UpperCaseCharacter = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z'
/** 2-character string to be used as ISO 3166-1 alpha-2: country code */
export type ISO3116_1_ALPHA_2 = `${UpperCaseCharacter}${UpperCaseCharacter}`

/**
 * An IAddress represents an address of an institution or person.
 */
export interface IAddress {
  "@type": string
  addressInfo?: string
  city?: string
  contactPerson?: string
  /** ISO 3166-1 alpha-2, types here as: a string with 2 characters */
  country?: ISO3116_1_ALPHA_2
  email?: string
  geoJson?: string[]
  name: string
  phone?: string
  postalCode?: string
  street?: string
  url?: string
}

/**
 * A IProposalContent represents a single key of content (contentId), that is or is not provided to a proposal.
 * The inner ObligationState defines, if this content must or could be included or if it is irrelevant.
 */
export interface IProposalContent {
  contentId: AvailableContentItems
  requirement: ObligationState
  version: number
}


/**
 * An IProposalAttachment represents an attachment to an proposal to a challenge. It is possibly connected to
 * an AttachmentDefinition of the challenge, where the proposal is created for.
 */
export interface IProposalAttachment extends INumericIdentifierModel {
  proposal?: IProposal | IFundApplication | IRI
  definition?: IAttachmentDefinition | IRI
  file?: File | IMediaObject
}

export interface IContactEmail {
  message: string
  senderName: string
  senderEmail: string
  topic: ContactTopic
}

/**
 * An ICategory is used to organize the categories of a project.
 * If a Category does not have properties, their display name should be created using their ID and translated via I18N.
 */
export interface ICategory extends INumericIdentifierModel {
  createdAt?: string
  createdBy?: IUser
  /** even longer description of the category */
  description?: string
  /** list of problems, related to a category */
  problems?: string[]
  program?: IProgram
  /** a longer describing subtitle */
  subtitle?: string
  /** short title of the category, e.g. "Kunst & Kultur"  */
  title: string
  updatedAt?: Date | string
  updatedBy?: IUser
}


/**
 * An ICashFlowEntry represents a single flow of cash.
 * It consists of a number of costs (count) at a certain point in time, with an optional note.
 */
export interface ICashFlowEntry {
  "@type": string
  timePoint: number
  unitCost: number
  quantity: number
  description?: string
}

/**
 * An ICostSubCategory represents the name of a subtype of costs, depending on a fund.
 * It allows to adopt the naming of a subtype to the specific needs of a fund.
 */
export interface ICostSubCategory {
  "@type": string
  /**
   * may be an element from the given enum or an custom CostSubCategory from the given fund
   */
  type: CostSubCategory | string
  /**
   * id of the fund
   */
  relatedFund?: number
}

/**
 * An ICredentials represents the login-data for a single user.
 */
export interface ICredentials {
  username: string
  password: string
}

/**
 * An IDiscussion represents an discussion, consisting of IFeedbackPost, possibly regarding to an IFeedbackInvitation
 * regarding a relatedObject
 */
export interface IDiscussion extends INumericIdentifierModel {
  /** when commenting a project (as relatedObject) a special aspect of that project may be commented. Which one is encoded in the contendId */
  contentId: string | AvailableContentItems
  /** date-time, when the discussion was created */
  createdAt?: Date | string // type: date-time
  /** user qho created tha discussion, may be null */
  createdBy?: IUser
  /** if the discussion was an answer to an FeedbackInvitation */
  feedbackInvitation?: IFeedbackInvitation | IRI
  /** date-time of the youngest post */
  lastPostDate?: Date | string
  /** number of posts to a discussion */
  postCount?: number
  /** the initial post is that one, that founded the discussion */
  initialPost: IFeedbackPost
  /** a discussion may relate to an object of type IProject, IProgram or IUser. Either relatedObject OR feedbackInvitation may be set */
  relatedObject?: IRI
}



/**
 * An IFeedbackInvitation represents an invitation for feedback
 */
export interface IFeedbackInvitation extends INumericIdentifierModel {
  /**
   * type: date-time | null
   */
  activeUntil?: Date | string | null
  anonymousFeedback?: boolean
  /**
   * type: date-time
   */
  createdAt?: Date | string
  createdBy?: IUser
  description?: string
  discussionCount?: number
  questions: IFeedbackQuestion[]
  /**
   * Receiver of an invitation may be the "user group" of an IProgram, IProject or a single IUser,
   * so the IRI of this entity is stored here.
   * Those users are allowed to answer to this FeedbackInvitation
   *
   * Or its "all users" (encoded by empty string), so no IRI is restricting the receiver group.
   */
  receiver?: IRI | ''
  relatedObject?: IRI
  title: string
  type?: string
}

/**
 * An IFeedbackPost represents a single post, connected to an IDiscussion answering to an IFeedbackInvitation
 */
export interface IFeedbackPost extends INumericIdentifierModel {
  /** single content-elements, 1 item for comments, more than 1 item for question-lists of FeedbackInvitations */
  content: FeedbackPostContent
  /** date-time, when the post has been created */
  createdAt?: Date | string
  /** user or null (when anonymous) */
  createdBy?: IUser
  /** related discussion of the post */
  discussion?: IDiscussion | IRI
  /** optional pre-post this FeedbackPost relates to; IRI-Reference */
  parent?: IRI
  /** type of the post */
  type: FeedbackPostType
}

/**
 * A IFeedbackQuestion represents a single question for an IFeedbackInvitation
 */
export interface IFeedbackQuestion {
  id: string
  explanation?: string
  question: string
  version: number
}

/**
 * An IChallenge represents a challenge, consisting of its name, description, timeline concretization-questions, needed
 * attachments for proposals and a list of received proposal.
 */
export interface IChallenge extends ISlugAndNumericIdentifierModel {
  "@type"?: ChallengeType
  attachmentDefinitions?: IAttachmentDefinition[] // list of definitions of attachments, that must or should be provided to the proposal
  attachmentsAvailable?: boolean // true, if attachments are accepted to a proposal
  concretizations?: IChallengeConcretization[] // additional questions that are provided to the project team
  criteria?: string[]
  description?: string
  directSubmission?: boolean
  implementationBegin?: Date | string
  imprint?: string
  logo?: IMediaObject
  name?: string
  // @todo multi rename to program: https://futureprojects.atlassian.net/browse/FCP-1437
  process?: IProgram | IRI
  /** required or wanted contents for a proposal for that specific challenge */
  proposalContents?: IProposalContent[]
  region?: string
  selectionProcess?: ChallengeSelectionProcess
  sponsor?: string
  state?: ChallengeState
  submissionBegin?: Date | string
  submissionEnd?: Date | string
  transitions?: ITransitionList<ChallengeTransitionState>
}

/**
 * An IFund is special challenge, which adds the option to support a project with money.
 * It only accepts fund applications as a proposal.
 */
export interface IFund extends IChallenge {
  /**
   * budget of the fund
   */
  budget?: number
  /**
   * is it required to attach costTypes to ResourceRequirements by applicants?
   */
  costTypesRequired?: boolean
  /**
   * which custom cost types for materialcosts are required by the fund
   */
  customMaterialCostTypes?: string[]
  fundingDuration?: number
  fundingRate?: number
  /**
   * what is the maximum rate per hour, a fund accepts for ownResources
   * (kalkulatorischer Stundensatz für erbrachte Eigenleistungen)
   */
  maxFundedHourlyRate?: number
  maximumGrant?: number
  minimumGrant?: number
  /**
   * is it possible to use ownResources (Eigenleistungen) in an application, if the fund-quota is less than 100%?
   */
  ownContributionsAccepted?: boolean
  /**
   * which CostSubCategory have to be assigned to ResourceRequirements of Type "material costs"
   */
  supportedMaterialCostTypes?: CostSubCategory[]
}

/**
 * An IFundApplication represents an application for a funding of a fund.
 * The Omit type is used to override the property state in IProposal
 */
export interface IFundApplication extends Omit<IProposal, 'state' | 'submissionData'> {
  requestedFunding?: number
  state?: FundApplicationState
  /** public readable submitted data*/
  submissionData?: IApplicationSubmissionData
  /**
   * @todo add thirdPartyFundsExplanation in a form for the user => currently no usage
   */
  thirdPartyFundsExplanation?: string // Explanation of the usage of Third-Party-Funds
}
/**
 * An IProposal represents an proposal for a challenge.
 */
export interface IProposal extends INumericIdentifierModel {
  "@type"?: ProposalType
  /** is this proposal activated for the current project? */
  active?: boolean
  /** is the proposal recommended for approvement? */
  approvalRecommendation?: ApprovalRecommendation
  /** is the proposal approved? ("bewilligt") */
  approved?: boolean
  /** list of attachments belonging to this proposal */
  attachments?: IProposalAttachment[]
  /** list of concretizations belonging to this proposal */
  concretizations?: { [id: number]: string }
  /** self assessment about the progress of the concretizations */
  concretizationSelfAssessment?: SelfAssessment
  /** challenge to which the proposal belongs: Entity or IRI */
  challenge?: IChallenge | IRI
  /** if the challenge has a voting: is this proposal licensed to take part in the voting? */
  eligibleForVoting?: boolean
  /** rank of the proposal within its recommondation */
  preselectionOrder?: number
  /**
   * project the proposal belongs to: Entity or IRI
   *
   * NOTE: if the proposal fetched by the processmanager by his proposal endpoint
   * this property is set with project entity stub data: @id, followerCount, memberCount, name, id, usedMemberRole.
   * For team members this property is undefined, b/c it is assumed that the user fetched the project prior to the proposal
   * or the proposal is delivered by the project.
   *
   * @todo: clarify: can this propery ever have an IRI?
   */
  project?: IProject | IRI
  /** Proposal-PDF, adopted to the chosen ContentManagement of a given challenge */
  proposalPdfFile?: IMediaObject
  /** self assessment about the progress of the proposal */
  proposalSelfAssessment?: SelfAssessment
  /** public readable submitted data */
  publicSubmissionData?: IProposalSubmissionData
  /** rank of the proposal in an (optional) voting of the challenge */
  publicVotingResult?: number
  /**
   * state of the proposal
   * is redefined for IFundApplication
   *
   * @todo: maybe rename entity attribute to submissionState to be more precise -> to be done in backend first: FCP-1188
   */
  state?: ProposalState
  /** all (even non-public) submitted data */
  submissionData?: IProposalSubmissionData
  /** all-inclusive PDF-file with all project-data as snapshot from the moment of the proposal-generation */
  teamPdfFile?: IMediaObject
  /** additional application text for taking part of a voting */
  votingApplication?: string
}

/**
 * An IAttachmentDefinition represents a definition of an attachment, that must/should/could attached
 * to a proposal for a challenge.
 */
export interface IAttachmentDefinition extends INumericIdentifierModel {
  content?: string
  description?: string
  challenge?: IChallenge | IRI
  maxSize?: number
  mimeTypes?: string[]
  required?: boolean
}

/**
 * An IChallengeConcretization represents a single question, that a challenge additionally asks an applicating project team.
 */
export interface IChallengeConcretization extends INumericIdentifierModel {
  description?: string
  challenge?: IChallenge | IRI
  image?: IMediaObject
  maxLength?: number
  question?: string
}

/**
 * Each goal consists of the a description itself as well as a text for measurability of Achievement of the goal.
 */
export interface IGoal {
  /** type of the IGoal format */
  "@type": string
  /** goal to be reached */
  goal: string
  /** how to measure that the goal is reached */
  measurability: string
}

/**
 * An IMediaObject represents a media object, e.g. an image. It is used to handle all kinds of files.
 */
export interface IMediaObject {
  contentUrl: string
  mimeType: string
  name: string
  originalName: string
  size: number
  dimensions?: [number, number]
  createdAt?: Date | string
  createdBy?: IUser
}

/**
 * An IProgram represents a participation program, organized by one tenant to generate ideas and projects.
 */
export interface IProgram extends ISlugAndNumericIdentifierModel {
  autoActivation?: boolean
  /**
   * Defines, if the communitymanagers of a program are allowed to access
   * the detailed data of the connected projects.
   * Changes the access permissions for project single calls at the backend API.
   *
   * Is only settable when the program is not activated yet to make the extended visibility
   * transparent to the project users.
   */
  canAccessProjectInternals?: boolean
  color?: string
  createdAt?: string
  createdBy?: IUser
  description?: string
  endDate?: Date | string
  geoBoundingBox?: [[number, number], [number, number]]
  geoPosition?: [number, number]
  locale?: string
  logo?: IMediaObject
  mailSenderName?: string
  name?: string
  isLocked?: boolean
  projectCount?: number
  provider?: IProvider | IRI
  region?: string
  state?: ProgramState
  targetGroup?: string
  timezone?: string
  updatedAt?: string
  updatedBy?: IUser
  vision?: string

  // the following properties belonging to the old IProcess
  // and there are only added here to enable a step by step migration of the schema, pages, components and tests.
  // @todo migrate https://futureprojects.atlassian.net/browse/FCP-1428
  /** @deprecated */
  maxTeamUploadSize?: number
  /** @deprecated */
  maxTeamUploadTotalSize?: number
  // @todo migrate https://futureprojects.atlassian.net/browse/FCP-1429
  /** @deprecated */
  imprint?: string
  // @todo https://futureprojects.atlassian.net/browse/FCP-1431
  /** @deprecated */
  criteria?: string[]
  /** @deprecated */
  goals?: string[]
  /** @deprecated */
  challenges?: IChallenge[]
  /** @deprecated */
  maxPublicDaysForSupportRequest?: number
}

export interface IBankDetails {
  "@†ype": string
  accountOwner?: string
  bankName?: string
  bic?: string
  iban?: string
}

/**
 * An IProject represents a project. Projects are central entities in the system. Most activities of the user aim
 * at improving project plans.
 */
export interface IProject extends ISlugAndNumericIdentifierModel {
  /**
   * Is this project accepted in the current program?
   *
   * Relevant for programs that make a check on (new) projects.
   */
  accepted?: boolean
  bankDetails?: IBankDetails
  categories?: IRI[] // @todo multi update the project import @see https://futureprojects.atlassian.net/browse/FCP-1683
  challenges?: string
  contact?: IAddress
  createdAt?: Date | string
  createdBy?: IUser
  /** ID of the entity at the external provider (if it comes from an external provider); probably a number. */
  externalIdentifier?: string
  /** Short name of the external provider (if the entity comes from an external provider). */
  externalProvider?: string
  description?: string
  descriptionExtension?: string
  delimitation?: string
  expectedInvolvedPersons?: number
  feedbackAllowedFor?: FeedbackRoles[]
  followerCount?: number
  followerships?: IProjectFollowership[]
  geoJson?: GeoJSON.Feature | GeoJSON.FeatureCollection
  goalExplanation?: string
  goals?: IGoal[]
  holder?: IAddress
  impact?: string[]
  implementationBegin?: Date | string
  implementationLocations?: string[]
  implementationState?: ImplementationState
  implementationTime?: number
  inspirationIdea?: IIdea
  inspirationProject?: IProject
  locked?: boolean
  /** Includes only coordinators, planners. No Observers. */
  memberCount?: number
  name?: string
  nextRatePlan?: null // @todo multi add IProjectRatePlan
  outcome?: string[]
  partners?: IProjectPartner[]
  pdfFile?: IMediaObject
  picture?: IMediaObject
  planSelfAssessment?: SelfAssessment
  program?: IProgram | IRI
  profileSelfAssessment?: SelfAssessment
  /** @deprecated use implementationState */
  progress?: ProjectProgress
  /**
   * @deprecated
   */
  proposals?: (IProposal | IFundApplication)[]
  ratePlan?: null // @todo mult add IProjectRatePlan
  ratePlanChangeDate?: Date | string // @todo multi just date or only string?
  ratePlanMetadata?: any[]
  resourceRequirements?: IResourceRequirement[]
  resultingProjectCount?: number
  /** @deprecated fetch via SubResource => currently not implemented in Backend */
  resultingProjects?: IProject[]
  results?: string[]
  sdgs?: SDG[]
  sdgExplanation?: string
  shortDescription?: string
  state?: ProjectState
  targetGroups?: string[]
  tasks?: IProjectTask[]
  teamContact?: IUser
  updatedAt?: Date | string
  updatedBy?: IUser
  utilization?: string
  visibility?: ProjectVisibility
  vision?: string
  visualization?: IMediaObject
  workPackages?: IWorkPackage[]
}

/**
 * Represents an idea that can be picked up any user to develop a project working on that idea.
 * An idea belongs to a program.
 */
export interface IIdea extends INumericIdentifierModel {
  categories?: IRI[]
  createdAt?: string
  createdBy?: IUser
  description?: string
  program?: IProgram | IRI
  resultingProjectCount?: number
  updatedAt?: string
  updatedBy?: IUser
}

/**
 * interface to centralize motivation and skills for several entity types
 */
export interface IMotivationAndSkills {
  motivation?: string
  skills?: string
}

/**
 * An IProjectCreation is an IProject, extended by additional fields to create a project.
 * Those additional fields are required when a user creates a project,
 * and are moved to his fresh project-membership backend-side.
 * @deprecated
 */
export interface IProjectCreation extends IMotivationAndSkills, IProject {
  // user: the creating user is implicit via token of the request when the new project is sent to the api: he should be logged in
  motivation: string
  skills: string
}

/**
 * An abstract interface that represents all relationships between project and user.
 * It is only used on client side and allows easier handling of equivalent client-side data structures
 * (i.e. IProjectFollowership and IProjectMembership).
 */
export interface AbstractProjectUserRelation extends IModel {
  project?: IProject | IRI // @todo: check: this is always an IProject @currentUser, never an IRI, or? Or is it converted to an IRI on writing data? @see https://futureprojects.atlassian.net/browse/FCP-1381
  user?: IUser | IRI
}

/**
 * An IProjectFollowership represents a followership connection between a user and a project.
 * This specific interface models project followerships that contains a project object (instead of IRI),
 * as it is sent from the API in get-user-self usecase.
 */
export interface IProjectFollowership extends AbstractProjectUserRelation, INumericIdentifierModel {
  createdAt?: Date | string
  /**
   * We specialize to IProject and omit string, b/c it's guaranteed that all received data (from API) will contain the object.
   * NOTE since the API requires strings on CREATE actions, we apply doc/principles/data-schema.md "Receiving objects, sending IRIs".
   * In this case, use `transformEntityToWriteDTO` from schema-dto.ts to get a `IProjectFollowershipDTO`.
   */
  project: IProject
}


/**
 * An IProjectImport represents a temporary object for a JSON-Upload and an optional new name for the new project
 */
export interface IProjectImport extends IMotivationAndSkills {
  file?: File
  newName?: string
  motivation: string
  skills: string
}



/**
 * An IProjectMembership represents a membership connection between a user and a project.
 * It consists of the users role and his skills and motivation to this single project.
 *
 * NOTE: ProjectMemberships IRI is different to other entity types, because it does not have an id:
 * /project_memberships/project=14;user=18
 */
export interface IProjectMembership extends IMotivationAndSkills, AbstractProjectUserRelation {
  /** NOTE: IRI/@id of a ProjectMembership has a special pattern */
  "@id"?: IRIOfProjectMembership
  role?: MembershipRole
}


/**
 * An IPartner represents an project partner and its contribution to the project.
 */
export interface IProjectPartner {
  "@type"?: string
  address?: IAddress
  contribution?: string
  domain?: string
  /**
   * uuid, @see addPartner
   * no IRI because it is not a seperate Entity
   */
  id?: string
  state?: PartnershipState
}

/**
 * An IProjectReport represents a misuse-report of a project.
 */
export interface IProjectReport {
  reporterEmail: string
  reporterName: string
  reportMessage: string
}

/**
 * An IProjectTask represents an task or a job to be done, to implement a project. To plan a project its central
 * to collect all necessary tasks. A task is fulfilled over a flow of time and it can be part of a work package.
 */
export interface IProjectTask {
  "@type": string
  description?: string
  id?: string
  /**
   * in which months of the projects implementationTime this task will be worked on
   * e.g. 1,2,3 for the first 3 months
   */
  months?: number[]
  result?: string
  title?: string
  /**
   * ID of the parent IWorkPackage
   * a uuid, not an IRI or number, @see addWorkPackage()
   */
  workPackage?: string
  order?: number
}

export interface ISupportRequest extends INumericIdentifierModel {
  content?: string
  /** project, for which a SupportRequest is created */
  project?: IProject
  /** timestamp: date-time, until the SupportRequest is public */
  publicUntil?: Date | string
  state?: SupportRequestState
  supporterRole?: SupporterRole
  title?: string
  updatedAt?: Date | string
  updatedBy?: IUser
}

/**
 * An IResourceRequirement represents a resource, that is needed to fulfill a task or a workpackage.
 * It consists of a list of CashFlow-Elements, those sum stands for the needes costs for that ResourceRequirement.
 *
 * A ResourceRequirement needs a source to be fulfilled.
 * A source has a sourceType: funding, ownResources, other.
 * A source can be a fund, own funds, contributions or material or thirdParty/other sources.
 * A source can point on a relatedSourceObject: a Fund-ID or a ProjectPartner-ID
 */
export interface IResourceRequirement {
  "@type": string
  cashFlow?: ICashFlowEntry[]
  /**
   * in which dimension is the cashflow measured?
   */
  cashFlowTimeDimension?: TimeDimension
  /**
   * type of costs, e.g. "personnel"
   */
  costCategory?: ResourceCostCategory
  /**
   * a list of fund-depending subType-Definitions
   */
  costSubCategories?: ICostSubCategory[]
  customUnit?: string
  customSource?: string
  description?: string
  ownContributionsExplanation?: string
  id?: string
  /**
   * valid fund-id (number) or partner-id (string)
   * partner id as uuid, @see addPartner
   */
  relatedSourceObject?: number | string
  /**
   * to precise the source of the resource
   */
  source?: ResourceSource
  /**
   * the type of the source
   */
  sourceType?: ResourceSourceType
  /**
   * ID of the parent IProjectTask (alternate to workPackage | null)
   */
  task?: string
  title: string
  /**
   * physical unit of (e.g. number, hours, km, meals, ...)
   */
  unit: ResourceUnit
  /**
   * for financialType === personnel only
   */
  weeklyHours?: number
  /**
   * ID of the parent IWorkPackage (alternate to task | null)
   */
  workPackage?: string
}

/**
 * Represents in the real world e.g. a company, a socialOrganization, a municipality.
 */
export interface ITenant extends ISlugAndNumericIdentifierModel {
  address?: IAddress
  invoiceAddress?: IAddress
  color?: string
  createdAt?: Date | string
  createdBy?: IUser
  geoBoundingBox?: [[number, number], [number, number]]
  geoPosition?: [number, number]
  isLocked?: boolean
  /** BCP 47/RFC 4646 language tag @see /locales/locales-config */
  locale?: string
  logo?: IMediaObject
  name?: string
  nature?: TenantNature
  customNature?: string
  /** Information for the platform owners: What is the intention of the tenant on this platform? */
  platformUsage?: string
  ratePlan?: IRatePlan | IRI
  ratePlanChangeDate?: Date | string
  ratePlanMetadata?: string[]
  nextRatePlan?: IRatePlan | IRI
  shortName?: string
  state?: TenantState
  timezone?: string
  updatedAt?: Date | string
  updatedBy?: IUser
  vatId?: string
  transitions?: ITransitionList<TenantTransitionState>
}

/**
 * An IProvider is a special ITenant with possible programs.
 *
 * Hints:
 * If custom program type is part of the programTypes, than the property customProgramType
 * is not allowed to be blank.
 * Require the coreProgram to belong to the provider itself.
 */
export interface IProvider extends ITenant {
  coreProgram?: IProgram
  customProgramType?: string
  programTypes?: ProgramType[]
}

/**
 * An IProposalSubmissionData represents an proposal to a challenge.
 * It collects the data stored with each submitted proposal, an immutable copy of most project properties.
 */
export interface IProposalSubmissionData {
  bankDetails: IBankDetails
  categories: IRI[]
  challengeName: string
  challenges: string
  challengeSlug: string
  concretizations: { [id: number]: string }
  contact: IAddress
  delimitation: string
  description: string
  descriptionExtension: string
  expectedInvolvedPersons: number
  geoJson: GeoJSON.Feature | GeoJSON.FeatureCollection
  goalExplanation: string
  goals: IGoal[]
  holder: IAddress
  impact: string[]
  implementationBegin: string
  implementationLocations: string[]
  implementationState: string
  implementationTime: number
  name: string
  outcome: string[]
  partners: IProjectPartner[]
  programName: string
  projectId?: number
  results: string[]
  resourceRequirements: IResourceRequirement[]
  sdgExplanation: string
  sdgs: SDG[]
  shortDescription: string
  slug: string
  submissionDate?: Date | string
  submittedBy?: {
    userId: number
    username: string
    firstName: string
    lastName: string
  }
  targetGroups: string[]
  tasks: IProjectTask[]
  teamContactName: string
  teamContactEmail: string
  utilization: string
  vision: string
  workPackages: IWorkPackage[]
}

/**
 * An IApplicationSubmissionData represents an application to a fund.
 */
export interface IApplicationSubmissionData extends IProposalSubmissionData {
  requestedFunding: number
  thirdPartyFundsExplanation: string
}

/**
 * ISysinfo is used to transfer information about the configuration of the running system from the backend to the client.
 */
export interface ISysinfo {
  accessBlock: {
    [key: string]: {
      interval: string
      limit: number
    }
  }
  applicationUrl: string
  defaultLocale: string
  defaultTimezone: string
  frontendUrl: string
  jwtTTL: number
  projectInactiveNotificationThreshold: number
  projectInactiveThreshold: number
  userVerificationRequired: boolean
}

/**
 * An ITeamUpload represents a single upload into the file-storage of a member of a project team.
 * It consists of the uploaded file as well as extra information to handle this file.
 */
export interface ITeamUpload extends INumericIdentifierModel {
  file?: File | IMediaObject
  category?: string
  content?: string
  project?: IProject | IRI
}



/**
 * An ITeamEmail represents an email, consisting of emaiSubject and emailMessage and a list of emailReceivers.
 */
export interface ITeamEmail {
  emailMessage: string
  emailSubject: string
  emailReceivers?: number[]
}

/**
 * An ITransitionList represents a transition in a workflow and the blockers, that prevent the transition to be performed.
 */
export type ITransitionList<TransitionState> = {
  [transitionName in TransitionState as string]: {
    blockers: {
      [code: string]: string
    }
  }
}

/**
 * Data structure defined by the backend to perform a transition.
 * It is send to the backend.
 */
export type TransitionInput<TransitionState> = {
  action: TransitionState
  message?: string
}

/**
 * An IRatePlan is used in the ITenant in order to determine how much the ITenant
 * for how long has to pay with what kind of features.
 *
 * note: the IRatePlan has no detailResult and usedPrivileges
 */
export interface IRatePlan {
  id?: number
  "@id"?: IRI
  createdAt?: Date | string
  createdBy?: IUser
  description?: string
  features?: string[]
  isDefault?: boolean
  name?: string
  prices?: number[]
  public?: boolean
  state?: RatePlanState
  updatedAt?: string
  updatedBy?: IUser
}



/**
 * An IUser represents a single user: username, name, email, several states and his memberships to projects.
 *
 * @todo multi anpassen des IUser's, u.a. roles durch privileges ersetzen: https://futureprojects.atlassian.net/browse/FCP-1411
 */
export interface IUser extends INumericIdentifierModel {
  aboutMe?: string
  createdAt?: string
  email?: string
  /**
   * provider name/identifier, in case the user is created by/connected to an external provider, e.g. an OAuth provider
   */
  externalProvider?: string
  firstName?: string
  lastName?: string
  /** BCP 47/RFC 4646 language tag @see /locales/locales-config */
  locale?: string
  /** is the user locked on the platform */
  locked?: boolean
  picture?: IMediaObject
  password?: string
  roles?: UserRole[]
  timezone?: string
  self?: boolean
  username?: string
  verified?: boolean

  // The feature projectfollowership is not implement in the multitenant. It will probably an UserObjectRole.
  // @see https://futureprojects.atlassian.net/browse/FCP-1477
  /** @deprecated */
  followerships?: IProjectFollowership[]

  // the following properties don't exist anymore in multitenant @todo multi delete the properties @see branch-info-single-multi.md
  /** @deprecated */
  active?: boolean
  /**
   * current project memberships of the user
   * @todo multi: delete this property after the project member applications are migrated
   *
   * @see https://futureprojects.atlassian.net/browse/FCP-1616
   * @deprecated use IUserProjectRole
   */
  projectMemberships?: IProjectMembership[]
  /**
   * projects that have been created by the user, even if he no longer is a member of the project team
   * includes created ideas
   *
   * @deprecated
   */
  createdProjects?: IProject[]
}

/**
 * A ValidUserObjectRole represents the connection of an user to an object (e.g. a project or an media object)
 * by a special role. It is necessary to organise the role-specific access rights of an user to an object.
 *
 * This abstract interface is extended by entity specific interfaces for entities that may have user-role-connections,
 * e.g. IUserProjectRole etc.
 *
 * This type ensures, that only valid UserObjectRoles are defined.
 * Note: The used numbers within the type definition could be all 1 or 0, but
 * it improves readability, b/c each number sequence is unique and represents one
 * specific UserObjectRole.
 *
 * If the combination of the given generic type parameters are invalid, never will be
 * returned. And to create objects with type never is not possible.
 *
 * Inspired from:
 *
 * @see https://casualdeveloper.net/post/2020-12-21-advanced-typescript-type-tricks/
 */
type ValidUserObjectRole<
  ObjectType extends UserObjectRoleObjectType,
  RoleType extends UserObjectRoleType,
  MetaDataType extends StringBasedStringArray | UserProjectMetaDataType
> =
  // define the properties of the type
  {
    /**
     * role which the user has on the object, e.g. "ProjectCoordinator" on a project entity
     */
    '@type': RoleType
    /**
     * timestamp when the UserObjectRole has been created
     */
    createdAt?: string
    /**
     * creator of the UserObjectRole
     */
    createdBy?: IUser
    /**
     * Date when a given role expires. Null if the role does not expire.
     */
    expiresAt?: Date | string
    /**
     * meta information connected to the role a user has to an object, e.g. motivation + skills for a user-project-role
     */
    metadata?: MetaDataType
    /**
     * the associated object for which the user has a role, e.g. within the project the user is a project planner
     */
    object: ObjectType
    /**
     * user whose role is defined: User object or IRI, null if user is deleted
     */
    user: IUser
  } & INumericIdentifierModel extends infer AbstractUserObjectRole
  ? (
    // define which generic type parameter combinations are allowed
    [
      ExtendsWithAllPropertiesRequired<IChallenge, ObjectType> extends never ? false : "challenge",
      RoleType extends ChallengeRole ? "challengeRole" : false,
      MetaDataType extends StringBasedStringArray ? "metadata" : false
    ] extends ["challenge", "challengeRole", "metadata"]
    ? AbstractUserObjectRole
    : [
      ExtendsWithAllPropertiesRequired<IProject, ObjectType> extends never ? false : "project",
      RoleType extends MembershipRole ? "membership" : false,
      MetaDataType extends UserProjectMetaDataType ? "projectMetadata" : false
    ] extends ["project", "membership", "projectMetadata"]
    ? AbstractUserObjectRole
    : [
      ExtendsWithAllPropertiesRequired<IProgram, ObjectType> extends never ? false : "program",
      RoleType extends ProgramRole ? "programRole" : false,
      MetaDataType extends StringBasedStringArray ? "metadata" : false
    ] extends ["program", "programRole", "metadata"]
    ? AbstractUserObjectRole
    : [
      ExtendsWithAllPropertiesRequired<ITenant, ObjectType> extends never ? false : "provider",
      RoleType extends TenantRole ? "tenantRole" : false,
      MetaDataType extends StringBasedStringArray ? "metadata" : false
    ] extends ["provider", "tenantRole", "metadata"]
    ? AbstractUserObjectRole
    : never // no other type parameter combination is allowed
  )
  : never // no other object definition than AbstractUserObjectRole is allowed

/**
 * Union type of valid management UserObjectRoles.
 */
export type IUserManagementRole = IUserTenantRole | IUserProgramRole | IUserChallengeRole

/**
 * Union type of valid UserObjectRoles.
 */
export type IUserObjectRole = IUserManagementRole | IUserProjectRole

/**
 * Collection of all EntityTypes, that are used in UserObjectRoles.
 */
export const entityTypeUserObjectRoleList = [EntityType.Project, EntityType.Challenge, EntityType.Program, EntityType.Provider] satisfies EntityTypeUserObjectRole[]

/**
 * All EntityTypes, that are used in UserObjectRoles.
 */
export type EntityTypeUserObjectRole = EntityType.Project | EntityType.Challenge | EntityType.Program | EntityType.Provider

/**
 * Derives the specific IUserObjectRole from UserObjectRoleObjectType,
 * e.g. DerivedIUserObjectRole<IProject> would be from type IUserProjectRole
 */
export type DerivedIUserObjectRole<ObjectType extends UserObjectRoleObjectType> =
  [
    ExtendsWithAllPropertiesRequired<ITenant, ObjectType> extends never ? "no" : IUserTenantRole,
    ExtendsWithAllPropertiesRequired<IProgram, ObjectType> extends never ? "no" : IUserProgramRole,
    ExtendsWithAllPropertiesRequired<IChallenge, ObjectType> extends never ? "no" : IUserChallengeRole,
    ExtendsWithAllPropertiesRequired<IProject, ObjectType> extends never ? "no" : IUserProjectRole
  ] extends infer IsSameTypeChecker
  ? (IsSameTypeChecker extends [IUserTenantRole, "no", "no", "no"]
    ? IUserTenantRole
    : IsSameTypeChecker extends ["no", IUserProgramRole, "no", "no"]
    ? IUserProgramRole
    : IsSameTypeChecker extends ["no", "no", IUserChallengeRole, "no"]
    ? IUserChallengeRole
    : IsSameTypeChecker extends ["no", "no", "no", IUserProjectRole]
    ? IUserProjectRole
    : never)
  : never

/**
 * Derives the specific IUserObjectRole from the given EntityType.
 */
export type IUORFromEntityType<AbstractEntityType extends EntityType> =
  [
    AbstractEntityType extends EntityType.Provider ? IUserTenantRole : "no",
    AbstractEntityType extends EntityType.Program ? IUserProgramRole : "no",
    AbstractEntityType extends EntityType.Challenge ? IUserChallengeRole : "no",
    AbstractEntityType extends EntityType.Project ? IUserProjectRole : "no"
  ] extends infer IsSameTypeChecker
  ? (IsSameTypeChecker extends [IUserTenantRole, "no", "no", "no"]
    ? IUserTenantRole
    : IsSameTypeChecker extends ["no", IUserProgramRole, "no", "no"]
    ? IUserProgramRole
    : IsSameTypeChecker extends ["no", "no", IUserChallengeRole, "no"]
    ? IUserChallengeRole
    : IsSameTypeChecker extends ["no", "no", "no", IUserProjectRole]
    ? IUserProjectRole
    : never)
  : never

/**
 * An IUserProjectRole has specific metaData containing skills and motivations.
 *
 * Notation is specified by the API.
 */
export enum UserProjectRoleMetaDataKey {
  Skills = "skills",
  Motivation = "motivation"
}

/**
 * Only skill and motivation are allowed in user project meta data.
 */
export type UserProjectMetaDataType = { [key in UserProjectRoleMetaDataKey]: string }

/**
 * Represents the role within a project.
 *
 * Following project properties are delievered:
 * "@id", "@type", "id",
 * "accepted", "name", "slug",
 * "state",
 * "visibility",
 * "locked"
 */
export type IUserProjectRole = ValidUserObjectRole<IProject, MembershipRole, UserProjectMetaDataType>

/**
 * An IUserTenantRole represents an Accountmanager or a TenantManager.
 */
export type IUserTenantRole = ValidUserObjectRole<ITenant | IProvider, TenantRole, StringBasedStringArray>

/**
 * An IUserProgramRole represents a CommunityManager.
 */
export type IUserProgramRole = ValidUserObjectRole<IProgram, ProgramRole, StringBasedStringArray>

/**
 * An IUserChallengeRole represents a ChallengeManager.
 */
export type IUserChallengeRole = ValidUserObjectRole<IChallenge | IFund, ChallengeRole, StringBasedStringArray>

/**
 * An IWorkPackage represents a work package of a project, than can be a connector to several tasks. Usually a
 * project consists of more than one work package and a work package consists of more than one task.
 */
export interface IWorkPackage {
  "@type": string
  description?: string
  /**
   * a uuid, @see addWorkPackage()
   * no IRI, because it is not a seperate Entity, but part of a JSON
   */
  id?: string
  mainResponsibility?: string
  name?: string
  /** for display (AP1, AP2, ...) and for sorting */
  order?: number
}



/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* using no prefix                                                            */
/* ************************************************************************** */

/**
 * This enum defines available states for the recommendation of a proposal.
 *
 * Notation is specified by the API.
 */
export enum ApprovalRecommendation {
  Open = "open",
  Recommended = "recommended",
  Alternate = "alternate",
  Reject = "reject"
}

/**
 * This enum defines available Types of a Challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeType {
  BasicChallenge = "BasicChallenge",
  Fund = "Fund"
}

/**
 * available selection process options to select proposals for granting
 *
 * may be extended by Jury, Voting etc.
 */
export enum ChallengeSelectionProcess {
  /** basic means: selection by process manager */
  Basic = "basic"
}

/**
 * Types of contact topics in a contact form for emailing process managers or the admin of the platform
 * The topic bugReport will lead to email the admins in the Api. Feedback will lead to email the process managers.
 * Currently used in IContactEmail
 *
 * Notation is specified by the API.
 */
export enum ContactTopic {
  BugReport = "bugReport",
  Feedback = "feedback"
}

/**
 * Types of Downloads
 *
 * NOTE: do not change without changing it it the backend, e.g. links in emails/pdf
 *
 * @todo: is this the right place for this enum?
 */
export enum DownloadType {
  ProposalAttachment = "proposal-attachment",
  ProposalPdf = "proposal-pdf",
  ProposalTeamPdf = "proposal-team-pdf",
  ProjectPdf = "project-pdf",
  TeamUpload = "team-upload",
}

/**
 * Contains all possible Sustainable Development Goals (SDGs), defined by the United Nations.
 */
export const SDGList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17] as const

/**
 * The 17 Sustainable Development Goals (SDGs) are defined by the United Nations.
 * Therefor a SDG is a number from 1 to 17.
 * @see https://www.plan.de/sdg-nachhaltige-entwicklungsziele.html?sc=IDQ24100
 */
export type SDG = typeof SDGList[number]

export enum FeedbackInvitationState {
  Active = 'active',
  Expired = 'expired',
  Preparing = 'preparation',
  All = 'all',
}

/**
 * Receiver for feeback invitations may be the connected users of a entity, so its IRI is
 * given as receiver, or it may be a different set of receivers, especially: 'all' users
 */
export enum FeedbackInvitationReceiver {
  /** all users as receivers. The string 'all' is given by the backend. */
  All = 'all'
}

/**
 * A FeedbackPostType frames possible types of posts, a user may contribute to an discussion by his post
 *
 * Notation is specified by the API.
 */
export enum FeedbackPostType {
  Hint = 'hint',
  Criticism = 'criticism',
  Troll = 'troll',
  Question = 'question',
  Support = 'support'
}

/**
 * FeedbackRoles frames possible roles, that may be allowed to give feedback
 *
 * Notation is specified by the API.
 */
export enum FeedbackRoles {
  Coordinator = 'coordinator',
  Planner = 'planner',
  Observer = 'observer',
  /** @deprecated does not exist anymore, should be replaced with CommunityManager */
  ProcessManager = 'processManager',
  RegisteredUser = 'registeredUser',
}

/**
 * The ProgramType frames possible program types, that the provider determines for his
 * programs.
 */
export enum ProgramType {
  CitizenParticipation = "citizenParticipation",
  Custom = "custom",
  FundManagement = "fundManagement"
}

/**
 * A ProposalState frames the possible states of an proposal for a challenge.
 *
 * Notation is specified by the API.
 */
export enum ProposalState {
  Preparing = "preparing",
  /** a proposal that is finished was submitted before (and may have had other states "after" submitted before being finished)  */
  Finished = "finished", // @todo eventuell State umbenennen in proposal.submissionstate
  Submitted = "submitted",
}

/**
 * States of a proposal transition.
 *
 * Notation is specified by the API.
 */
export enum ProposalTransitionState {
  Submit = "submit",
  Finish = "finish"
}

/**
 * Types of a proposal, based on the Challenge/Fund it is connected to.
 *
 * Notation is specified by the API.
 */
export enum ProposalType {
  BasicProposal = "BasicProposal",
  Fundapplication = "FundApplication"
}

/**
 * ApplicationState describes the additional states of a Fundapplication, additional to ProposalState
 * coming after ProposalState.Submitting
 *
 * Notation is specified by the API.
 */
export enum ApplicationState {
  AwaitingAcception = 'awaiting_acceptation',
  Fulfilling = 'fulfilling',
  Postprocessing = 'postprocessing'
}

/**
 * A FundApplication has the same states like a proposal regarding its progress to a challenge,
 * extended by additional states of a FundApplication.
 *
 * Because it is not possible in typescript to extend an enum the Uniontype is used as a workaround.
 * This solution mirrors the relationship of Proposal and FundApplication in the Backend.
 */
export type FundApplicationState = ProposalState | ApplicationState

/**
 * ChallengeState frames the possible states of a challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeState {
  Created = "created",
  Published = "published",
  Open = "open",
  Paused = "paused",
  Deciding = "deciding",
  FulFilling = "fulfilling",
  Clearing = "clearing",
  Finished = "finished",
}

/**
 * ChallengeTransitionState frames the possible transitions of a challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeTransitionState {
  Publish = "publish",
  Open = "open",
  Pause = "pause",
  Close = "close",
  Grant = "grant",
  Conclude = "conclude",
  Finish = "finish",
}

export enum ProjectVisibility {
  /**
   * Everybody can see everything from that project, as if they were an
   * observer. Only limited by a program's restricted user group if applicable.
   * Also, the project members are not visible (again as it is for observers).
   */
  Transparent = 'transparent',
  /**
   * Default: Profile data visible to anybody (respecting the program's
   * restricted user group), private data only to members.
   */
  Visible = 'visible',
  /**
   * Same as "visible", with the only difference being that limited projects
   * are *not* returned in the program's project collection. Direct access
   * via ID/slug is still possible. The collection can still be filtered for
   * limited projects by related managers.
   */
  Limited = 'limited',
  /**
   * Hidden projects are not returned in a program's project collection and
   * cannot be accessed via ID/slug, except by project members. The collection
   * can still be filtered for hidden projects by related managers.
   */
  Hidden = 'hidden'
}

/**
 * A CostSubCategory represents a subtype of costs for (currently) Materialcosts/Sachkosten, e.G. "rent".
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum CostSubCategory {
  //  Administrative = "administrative",
  Honorarium = "honorarium",
  Rent = "rent",
  Subsistence = "subsistence",
  Traveling = "traveling",
  Other = "other"
  //  Fees = "fees",
  //  Custom = "custom", // for custom source types
}

/**
 * Notation is specified by the API.
 *
 * Order is important and determines the order of entries in the UI.
 */
export enum TenantNature {
  Municipality = "municipality",
  SocialOrganization = "socialOrganization",
  Company = "company",
  Custom = "custom",
}

/** Notation is specified by the API. */
export enum TenantTransitionState {
  Activate = "activate",
  End = "end"
}

/**
 * These data inputs representing the 2 possible tenant transitions: "end a tenant" and "activate a tenant",
 * which are used to send to the backend.
 *
 * @see transitionEntity in client.ts
 *
 * Inputs specified by the API.
 */
/** @todo multi add other transitions: challenge, fund, proposal, fundapplication, program @see https://futureprojects.atlassian.net/browse/FCP-1553 */
export const EndTenantTransitionInput: TransitionInput<TenantTransitionState> = { "action": TenantTransitionState.End }
export const ActivateTenantTransitionInput: TransitionInput<TenantTransitionState> = { "action": TenantTransitionState.Activate }

/**
 * A MembershipRole frames the possible roles for a project team member.
 *
 * Notation is specified by the API.
 */
export enum MembershipRole {
  Coordinator = "ProjectCoordinator",
  Observer = "ProjectObserver",
  Planner = "ProjectPlanner",

  // @todo multi: is this correct? (not implemented on backend, but required to keep FollowerButton test running)
  Follower = "ProjectFollower",

  /** @todo multi delete this role: https://futureprojects.atlassian.net/browse/FCP-1442
   *
   * @deprecated
   */
  Applicant = "applicant",
}

/**
 * An user can be an AccountManager or a TenantManager of a tenant.
 *
 * Notation is specified by the API.
 */
export enum TenantRole {
  Accountmanager = 'AccountManager',
  TenantManager = 'TenantManager'
}

/**
 * An user can be the CommunityManager of a program and its projects.
 *
 * Notation is specified by the API.
 */
export enum ProgramRole {
  CommunityManager = 'CommunityManager'
}

/**
 * An user can be the ChallengeManager of a challenge and its proposals or of a fund and it's applications.
 *
 * Notation is specified by the API.
 */
export enum ChallengeRole {
  ChallengeManager = 'ChallengeManager'
}

/** conclusion of all platform wide existing user role types */
export type UserObjectRoleType = ChallengeRole | ProgramRole | TenantRole | MembershipRole

/** summary of all object type for which UserObjectRoles exists */
export type UserObjectRoleObjectType = IChallenge | IProgram | ITenant | IProject

/**
 * A ObligationState frames, how a data must be handled: the relevant data is: NotWanted, Required or Optional
 * Currently (07.08.2022) only NotWanted and Required are used in ProposalContent-Definition.
 *
 * Notation is specified by the API.
 */
export enum ObligationState {
  NotWanted = "",
  Optional = "optional",
  Required = "required",
}

/**
 * An PartnershipState frames the options for the status of a partnership
 *
 * Notation is specified by the API.
 */
export enum PartnershipState {
  Accepted = "accepted",
  Open = "open",
  Requested = "requested",
}

/**
 * The ImplementationState frames the state of a project regarding its implementation.
 *
 * These values are allowed when the coordinator changes the
 * implementationState, no further rules are applied on the backend.
 *
 * Notation is specified by the API.
 */
export enum ImplementationState {
  /** project is still in earliest self-discovery/self-description phase */
  Profiling = "profiling", // neuer Status => früher: ProjectProgress.CreatingProfile
  /** project is planning */
  Planning = "planning", // neuer Status => früher: ProjectProgress.CreatingPlan
  /** project is looking for members/resourcers/materials ... */
  Sourcing = "sourcing", // neuer Status
  /** project is in implementing phase */
  Implementing = "implementing", // neuer Status
  /** project is finished */
  Finished = "finished",
}

/**
 * Contains all ImplementationStates in a beginning to ending order.
 */
export const implementationStateOrder: ImplementationState[] = [
  ImplementationState.Profiling,
  ImplementationState.Planning,
  ImplementationState.Implementing,
  ImplementationState.Sourcing,
  ImplementationState.Finished
]

/**
 * The ProjectProgress frames possible states for a project progress: how far it has evolved.
 *
 * Notation is specified by the API.
 * @deprecated replaced by adapted ImplementationState
 * @todo multi delete after onboarding, marketplace and management are is refactored
 */
export enum ProjectProgress {
  Idea = "idea",
  CreatingProfile = "creating_profile",
  CreatingPlan = "creating_plan",
  CreatingProposal = "creating_proposal",
  SubmittingProposal = "submitting_proposal",
  ProposalSubmitted = "proposal_submitted",
}

/**
 * The ProjectProgress frames possible states of a project, especially if it is (still) active.
 *
 * Notation is specified by the API.
 */
export enum ProjectState {
  Active = "active",
  Inactive = "inactive",
  Deactivated = "deactivated",
}

/**
 * The ProgramState frames possible states of a program.
 *
 * Notation is specified by the API.
 */
export enum ProgramState {
  Active = "active",
  Created = "created",
  Ended = "ended"
}

/**
 * Contains all existing states of programs as array
 * to be used in code and tests to ensure complete list with same order.
 */
export const allProgramStates: ProgramState[] = Object.values(ProgramState)

export enum RatePlanState {
  Active = "active",
  Created = "created",
  Feprecated = "deprecated"
}
/**
 * A ResourceFinancialType frames possible types of costs, to form a consistent resource plan.
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceCostCategory {
  Investment = "investment",
  Material = "material",
  Personnel = "personnel",
  //  Proceeds = "proceeds",
  Other = "other",
  UnSet = ""
}


/**
 * A ResourceSource frames possible sources for ResourceRequirements, depending on the chosen SourceType
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceSource {
  Custom = 'custom',
  Fund = 'fund', // when sourceType === funding
  //  OwnContribution = 'ownContribution', // when sourceType === OwnResources
  OwnFunds = 'ownFunds', // when sourceType === OwnResources
  OwnMaterialContribution = "ownMaterialContribution", // when sourceType === OwnResources
  OwnEffortContribution = 'ownEffortContribution',
  Partner = 'partner', // when sourceType === ThirdParty
  NoSource = ''
}

/**
 * A ResourceUnit frames the options of units, a resource can be measured in.
 *
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * Notation is specified by the API.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceUnit {
  Custom = "custom",
  Flat = "flat",
  Pieces = "pieces",
  Hours = "hours",
  Months = "months",
  Years = "years",
  Kilometers = "kilometers",
  OvernightStays = "overnightStays"
}


/**
 * A ResourceSourceType represents a possible source for a needed resource or the needed amount of money.
 *
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceSourceType {
  Funding = "funding",
  OwnResources = "ownResources",
  Proceeds = "proceeds",
  ThirdParty = "thirdParty", // for custom source types
}


/**
 * A SelfAssessment frames the options of how far a project or a part of it has evolved. It is used by the users themself,
 * thats why they are called SelfAssessments.
 */
export enum SelfAssessment {
  Starting = 0,
  MakingProgress = 25,
  HalfFinished = 50,
  AlmostFinished = 75,
  Complete = 100,
}

/**
 * A SupportRequest has to have one support role. What is the project looking for?
 */
export enum SupporterRole {
  /** Sponsor/Geldgeber */
  Sponsor = 'sponsor',
  /** Dienstleister */
  Supplier = 'supplier',
  /** Feedbackgeber & Austauschpartner */
  FeedbackProvider = 'feedback_provider',
  /** Experten & Wissensträger */
  Expert = 'expert',
  /** Zugangschaffer & Botschafter */
  Ambassador = 'ambassador',
  /** Mitwirkende: Teammitglieder & Mitstreiter */
  Contributor = 'contributor',
}

export enum SupportRequestState {
  Hidden = 'hidden',
  Public = 'public',
  Closed = 'closed'
}

/**
 * TimeDimension frames the options of a monthly or yearly view on time-relevant data, e.g. the ICashFlowEntry.
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum TimeDimension {
  Monthly = "monthly",
  UnSet = "",
  Yearly = "yearly"
}

/**
 * The TenantState frames possible states of a tenant.
 *
 * Notation is specified by the API.
 */
export enum TenantState {
  Active = "active",
  Requested = "requested",
  Ended = "ended"
}

/**
 * Contains all existing states of tenants as array
 * to be used in code and tests to ensure complete list with same order.
 */
export const allTenantStates: TenantState[] = Object.values(TenantState)

/**
 * An UserRole frames the options for Roles, that exists in the system.
 *
 * Notation is specified by the API.
 *
 * @todo multi refactor ProcessManager to PlatformManager and delete Admin
 * @see https://futureprojects.atlassian.net/browse/FCP-1441
 */
export enum UserRole {
  User = "ROLE_USER",
  PlatformManager = "ROLE_PLATFORM_MANAGER",
  // @todo should not be an allowed role for any actions, added here for authenticated links
  Guest = "ROLE_GUEST",

  // @todo here for loading users and determining if the entity was loaded when the currentuser
  // was logged in or in the state from registration etc.
  Self = "self",

  // @todo multi following user roles have to be removed and permissions have to adapted, checked by UserObjectRoles
  // https://futureprojects.atlassian.net/browse/FCP-1440
  /** @deprecated */
  ProcessManager = "ROLE_PROCESS_MANAGER",
}

/**
 * An Userprivilege determines how much a user can see of a certain entity
 *
 * Notation is specified by the API.
 */
export enum UserPrivileges {
  // only forFund, Project, Program, Tenant
  Read = "read",
  // only forFund, Project, Program, Tenant
  ReadPrivate = "readPrivate",
  // only for FundApplication, Proposals
  ReadAdministrative = "readAdministrative",
}

/**
 * Is used to determine in an email, which user will receive the email.
 * Currently, simply to decide, if all users or a selection of users will receive the email.
 *
 * Notation is specified by the API.
 */
export enum UserEmailReceiverOption {
  All = "ALL_USERS",
  Selection = "SELECTED_USERS"
  // @todo Question add userRole as Filter?
}