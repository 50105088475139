import { useSelector } from "react-redux"

import { UserRole } from "@api/schema"
import AuthElement from "@components/common/AuthElement"
import DropdownComponent from "@components/common/DropdownComponent"
import FAQFlyoutTriggerIcon from "@components/common/faq/FAQFlyoutTriggerIcon"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { Routes } from "@definitions/routes"
import { AppState } from "@redux/reducer"
import { selectIsAuthenticated } from "@redux/reducer/auth"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { useTooltip } from "@services/tooltipHook"
import { humanTime } from "@services/util"
import { MODULE_LOGOUT_TIMER_AVAILABLE } from "config"

import NavIcon from "./NavIcon"
import NavigationDropDownItem from "./NavigationDropDownItem"

const usedNamespaces: NamespaceShortcut[] = ["base-layout", "goto"]

interface IProps {
  // @todo diese ganzen Properties nicht aus den Elternkomponenten holen sondern
  // aus mapStateToProps damit die Elternkomponenten nicht neu gerendert werden weil sich
  // props ändern die sie nicht brauchen sondern nur durchreichen: https://i.imgur.com/xpwcEH3.png
  authTTL: number
  doLogout: (redirect: Routes) => void
  isFrontPage?: boolean
}

/**
 * provides the navigation icons on every page of the application
 */
const HeaderIconNavigation: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()

  const { tooltipTargetRef, ToolTip } = useTooltip(t("base-layout", "timeTillLogout"))
  const isLoggedIn = useSelector((state: AppState) => selectIsAuthenticated(state))

  return <nav role="navigation" className="icon-navigation">
    <AuthElement requiredUserRole={UserRole.PlatformManager}>
      <NavIcon href={Routes.PlatformManagerDashboard} icon="monitor" title={t("goto", "platform.dashboard")} />
    </AuthElement>

    <NavIcon
      href={Routes.MarketOfPrograms}
      icon="market"
      title={t("base-layout", "marketplace")}
    />

    <NavIcon href={Routes.SingleMultiCurrentProgram} icon="info-bubble" title={t("base-layout", "programOverview")} />

    <DropdownComponent
      autoWrap={false}
      tooltipPlacement="top"
      button={
        <span className="actionicon"><Icon name="user" size={24} /></span>
      }
      light={props.isFrontPage}
      title={t("base-layout", "user")}
    >
      {isLoggedIn &&
        <NavigationDropDownItem
          label={t("base-layout", "userDashboard")}
          href={Routes.UserDashboard}
        />
      }

      {isLoggedIn &&
        <NavigationDropDownItem
          label={t("base-layout", "myProjects")}
          href={Routes.MyProjects}
        />
      }
      {isLoggedIn &&
        <NavigationDropDownItem
          label={t("base-layout", "logout")}
          onClick={() => props.doLogout(Routes.MarketOfPrograms)}
        />
      }
      {!isLoggedIn &&
        <NavigationDropDownItem
          href={Routes.Login}
          label={t("base-layout", "login")}
        />
      }
    </DropdownComponent>

    {MODULE_LOGOUT_TIMER_AVAILABLE && props.authTTL > 0 && isLoggedIn && <>
      <div ref={tooltipTargetRef}>{humanTime(props.authTTL)}</div>
      {ToolTip}
    </>
    }

    <FAQFlyoutTriggerIcon className="actionicon faq-icon" />
  </nav >
}

export default withDynamicNamespaces<IProps>(HeaderIconNavigation, usedNamespaces)